import { MarkdownDataNode, MarkdownEventDataNode } from './types/index';

/**@func findMostRecentlyPastIndex
 * Finds index of most recently past Markdown post. Implemented via a modified binary search.
 * @param {MarkdownDataNode[] | MarkdownEventDataNode[]} edges Array of Markdown nodes. Assumes dates are sorted in ASC order!
 * @param {number} date Optional. Date from which to search.
 * @return {number} The index of the most recently past Markdown post. Returns -1 if current date is the earliest date.
 * @throws Error if edges size is 0.
 */
export const findMostRecentlyPastIndex = (
    edges: MarkdownDataNode[] | MarkdownEventDataNode[],
    date: number = Date.now()
): number => {
    if (edges.length === 0) {
        throw Error('Error: There are no Markdown nodes for searching!');
    }

    const currDate = date;

    // Case: Returns latest post if today is latest date
    // Case: Returns -1 if today is earliest date
    if (Date.parse(edges[edges.length - 1].node.frontmatter.date) < currDate) {
        return edges.length - 1;
    } else if (Date.parse(edges[0].node.frontmatter.date) > currDate) {
        return -1;
    }

    // Case: Return index of most recently past post
    const dates = edges.map((edge: MarkdownDataNode | MarkdownEventDataNode) =>
        Date.parse(edge.node.frontmatter.date)
    );

    return lowestNeighborIndexBinarySearch(dates, currDate);
};

/**@func lowestNeighborIndexBinarySearch
 * Finds via binary search: the index of the query value, of the nearest lower array value, or of the lowest array value if query value is the lowest.
 * @param {number[]} arr Array of numbers. Assumes dates are sorted in ASC order!
 * @param {number} val Value whose index to search for.
 * @return {number} The index of the query value. Otherwise, the index of the lowest neighbor.
 * @throws Error if array size is 0.
 */
export const lowestNeighborIndexBinarySearch = (
    arr: number[],
    val: number
): number => {
    if (arr.length === 0) {
        throw Error('Error: Array has size less than 1!');
    }

    let currIndex = Math.floor(arr.length / 2);
    let finalStep = Math.floor(Math.log2(arr.length)) - 1;
    let indexModifier = 0;

    for (let i = 0; i < Math.floor(Math.log2(arr.length)); i++) {
        if (arr[currIndex] === val) {
            return currIndex;
        }

        indexModifier = Math.max(
            Math.floor(Math.floor(arr.length / (2 * (i + 1))) / 2),
            1
        );

        if (arr[currIndex] < val) {
            //Look into upper half
            if (
                i < finalStep ||
                (arr.length % 2 === 0 && val >= arr[currIndex + indexModifier])
            ) {
                currIndex += indexModifier;
            }

            // Corner Case for two-off odd-sized arrays
            if (
                i === finalStep &&
                (arr.length % 2 === 1 && val >= arr[currIndex + indexModifier])
            ) {
                currIndex += indexModifier;
                // Inner Corner Case for one-off odd-sized arrays
                if (
                    currIndex !== arr.length &&
                    val >= arr[currIndex + indexModifier]
                ) {
                    currIndex += indexModifier;
                }
            }
        } else if (arr[currIndex] > val) {
            //Look into lower half
            currIndex -= indexModifier;
            // Corner Case due to one-off
            if (i === finalStep && (currIndex != 0 && val < arr[currIndex])) {
                currIndex -= indexModifier;
            }
        }
    }
    return currIndex;
};

/**@func getMonthAbrvEN
 * Converts a number into the corresponding month abbreviation in English. JAN is index 0.
 * @param {number} month Value whose month to search for.
 * @return {string} The abbreviation in English of the query value. Otherwise, JAN.
 */
export const getMonthAbrvEN = (month: number): string => {
    switch (month) {
        case 0:
            return 'JAN';
        case 1:
            return 'FEB';
        case 2:
            return 'MAR';
        case 3:
            return 'APR';
        case 4:
            return 'MAY';
        case 5:
            return 'JUN';
        case 6:
            return 'JUL';
        case 7:
            return 'AUG';
        case 8:
            return 'SEP';
        case 9:
            return 'OCT';
        case 10:
            return 'NOV';
        case 11:
            return 'DEC';
        default:
            console.error(
                'Error: Invalid month index. Must be integer n, where 0 > n > 11 .'
            );
            return 'ERR';
    }
};

/**@func getNetlifyCMSFilename
 * Obtains filename of paths generated by Netlify CMS (i.e. starting with or containing '/').
 * @param {string} path Path of file generated by Netlify CMS.
 * @return {string | null} Name of file, else, with invalid path structure, null.
 */
export const getNetlifyCMSFilename = (path: string): string | null => {
    let regex = /(.*\/)(.*\.(?:jpe?g|png))$/;
    let matches = path.match(regex);
    if (matches) {
        // [0] = whole path, [1] = path before filename, [2] = filename
        return matches[2];
    } else {
        console.error(
            `Error: Invalid path structure. Path <${path}> Must contain / .`
        );
        return null;
    }
};

/**@func utcStringToDateString
 * Provides the DD MM YYYY string representation of the UTC date string.
 * @param {string} utc UTC date string to convert.
 * @return {string} DD MM YYYY string representation of the input date string.
 */
export const utcStringToDateString = (utc: string) => {
    const date = new Date(utc);
    return `${date.getDate()} ${getMonthAbrvEN(
        date.getMonth()
    )} ${date.getFullYear()}`;
};
